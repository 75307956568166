import React from "react";
import { Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ankolacamp from "../img/ankolacamp.png"
import ankolacampeng from "../img/ankolacampEng.png"
import "../App.css";
import { Link } from "react-router-dom";


export default function eventPage() {
  return (
    <>
      <Card
        className="text-center"
        border="0"
        fluid
        style={{backgroundColor: "gray",padding: "10px" }}
      >
            <h1 style={{ color: "#FFF", padding: "15px", textShadow: "2px 2px 4px #000000" }}>Upcoming Events  </h1>
        <img
          src={ankolacamp}
          className="rounded"
          alt="Camp image"
          style={{ width: "100%", height: "auto" }}
        />
      </Card>
      <Card
        className="text-center"
        border="0"
        fluid
        style={{backgroundColor: "gray",padding: "10px" }}
      >
            
        <img
          src={ankolacampeng}
          className="rounded"
          alt="Camp image"
          style={{ width: "100%", height: "auto" }}
        />
      </Card>
      <Card>
          <Card.Body>
            <Card.Title>Health Camps</Card.Title>
            <Card.Text>
              Starting from 2024 we have been conducting Health Camps along with many socially responsible associations in Karnataka capital Bengaluru rural. 
              And recently we concluded that, we will conduct Health Camps only where it's requirement is more. In Uttara Kannada district there ain’t a single Multi-Speciality hospital, one of the biggest district of Karnataka, even after 78 years of independence.
               And the people across the district are forced to depend on the either hospitals in Manipal, Udupi, Mangalore or Panjim, Goa.  
            </Card.Text>
            <Link to="/comingSoon">
                <Button variant="primary"> Forms </Button>
              </Link>
       </Card.Body>   
       </Card>
    </>
  );
}
